import React from "react";

export const AlternativeApp = () => {
    return <div style={{textAlign: "center", maxWidth: "960px", margin: "30px auto"}}>
        <h1 style={{textAlign: "center", margin: "auto"}}> Practice Interviewing with AI</h1>
        <h2>Contact</h2>
        <div className="description no-max-width-mobile">
            Our new <span className="action">AI powered</span> platform allows
            you to practice behavioral and technical questions like{" "}
            <span className="action">Product Management Case Studies</span> and
            more.
        </div>
        <h2>Contact</h2>
        <p>
            Please find us at{" "}
            <a href="mailto:hello@offerletterai.com">hello@offerletterai.com</a>
        </p>
    </div>
}