import React from "react";
import ReactDOM from "react-dom/client";
import "./theme.scss";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";
import { apiInstance } from "./api/baseApi";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import {AlternativeApp} from "./components/app/AlternativeApp";

let clientToken = "pubd650f0fe40cfd2879d8e7a32f5e2dfa5";
datadogLogs.init({
  clientToken: clientToken,
  site: "us5.datadoghq.com",
  service: "offerletterai",
  version: "1.0.0",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

datadogRum.init({
  applicationId: "6b1c9461-1a30-4825-b7b2-d1b0645fd433",
  clientToken: "pub5e9c73265a183ac224bdb361512107c6",
  site: "us5.datadoghq.com",
  service: "openletteai",
  env: "PROD",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);


root.render(<AlternativeApp />);
// root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
